@import "src/shared/styles/pxToRem";

.container {
    width: 100%;

}

.buttonsBlock {
    display: flex;
    gap: pxToRem(12px);
}