@import '/src/shared/styles/pxToRem.scss';

.wrapper{
    width:pxToRem(1920px);
    height:pxToRem(1080px);
    position:relative;
    & .stepper{
        position:absolute;
        top:pxToRem(40px);
        left:pxToRem(806px);
        width:pxToRem(307px);
        height:pxToRem(98px);
    }
    & .playerInfoForm{
        position:absolute;
        top:pxToRem(110px);
        left:pxToRem(31px);
    }
    & .actionButtonsBlock{
        position:absolute;
        top:pxToRem(965px);
        left:pxToRem(1171px);
    }
}