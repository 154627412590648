@import 'src/shared/styles/pxToRem';

.container {
    height: pxToRem(173px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Brutal Type', normal;
}

.title {
    font-size: pxToRem(28px);
    font-weight: 700;
    font-size: pxToRem(28px);
    line-height: pxToRem(20px);
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-bottom: 5px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.timeTitle {
    font-size: pxToRem(28px);
    font-weight: 700;
    font-size: pxToRem(28px);
    line-height: pxToRem(20px);
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-bottom: 5px;
    width: pxToRem(105px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .amPm {
        font-size: pxToRem(15px);
        line-height: 0.85;
    }
}

.subtitle {
    font-weight: 500;
    font-size: pxToRem(15px);
    line-height: pxToRem(20px);
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.8);
}

.line {
    width: pxToRem(90px);
    height: pxToRem(1px);
    background-color: white;
    border-radius: pxToRem(5px);
}

.street {
    font-weight: 500;
    font-size: pxToRem(15px);
    line-height: pxToRem(20px);
    color: rgba(255, 255, 255, 0.8);
}
