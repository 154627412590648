@import '/src/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.title {
    position: absolute;
    top: pxToRem(144px);
    left: pxToRem(47px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    text-align: center;
    color: #FFFFFF;
}

.mainGrid {
    position: absolute;
    top: pxToRem(190px);
    left: pxToRem(47px);
    display: grid;
    grid-template-columns: repeat(3, pxToRem(184px));
    grid-template-rows: pxToRem(78px) pxToRem(78px) pxToRem(43px);
    gap: pxToRem(8px);
}

.withdrawalValue {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(30px);
    line-height: pxToRem(35px);
    color: #2F9356;
}
.withdrawalValue:hover{
    cursor:pointer;
}

.otherValue {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: #FFFFFF;
}

.input {
    box-sizing: border-box;
    position:relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: pxToRem(184px);
    height: pxToRem(43px);
    z-index:2;
    background: #2F9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px) rgba(0, 0, 0, 0.12);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(30px);
    line-height: pxToRem(14px);
    color: rgba(255, 255, 255, 1);
    border:none;
    padding: pxToRem(10px);
    opacity:0;
}
.input:focus{
    outline: none;
}
.input::placeholder{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(12px);
    line-height: pxToRem(14px);
    color: rgba(255, 255, 255, 0.5);
}

.mask {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    top:pxToRem(0px);
    left:pxToRem(0px);
    z-index:1;
    width: pxToRem(184px);
    height: pxToRem(43px);
    background: #2F9356;
    box-shadow: pxToRem(0px) pxToRem(0px) pxToRem(19px) pxToRem(4px) rgba(0, 0, 0, 0.12);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(30px);
    line-height: pxToRem(14px);
    color: rgba(255, 255, 255, 1);
    padding: pxToRem(10px);
}

.notValue{
    font-size: pxToRem(12px);
    color: rgba(255, 255, 255, 0.5);
}