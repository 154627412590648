@import '/src/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.mainInfo{
    position: absolute;
    top:pxToRem(152px);
    left:pxToRem(198px);
    display:flex;
    flex-flow:column nowrap;
    justify-content: center;
    align-items: center;
    width: pxToRem(265px);
    height: pxToRem(226px);
    background: #FFFFFF;
}

.mainInfo img:first-child{
    width:pxToRem(102px);
    height:pxToRem(102px);
}
.mainInfo div:nth-child(2){
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    text-align: center;
    color: #2F9356;
    margin-top:pxToRem(13px);
}
.mainInfo div:last-child{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(30px);
    line-height: pxToRem(35px);
    color: #2F9356;
}