@import '/src/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.title {
    position: absolute;
    top: pxToRem(170px);
    left: pxToRem(47px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    text-align: center;
    color: #FFFFFF;
}

.mainInfo {
    position: absolute;
    top: pxToRem(198px);
    left: pxToRem(47px);
    width: pxToRem(566px);
    height: pxToRem(78px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.value {
    box-sizing: border-box;
    z-index: 2;
    border:none;
    width: pxToRem(299px);
    height: pxToRem(78px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2F9356;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(36px);
    line-height: pxToRem(42px);
    text-align: center;
    color: #FFFFFF;
    opacity:0;
}

.value:focus{
    outline: none;
}

.mask {
    box-sizing: border-box;
    position: absolute;
    top:pxToRem(0px);
    left:pxToRem(0px);
    border:none;
    width: pxToRem(299px);
    height: pxToRem(78px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2F9356;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(36px);
    line-height: pxToRem(42px);
    text-align: center;
    color: #FFFFFF;
}

.info {
    width: pxToRem(263px);
    height: pxToRem(78px);
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
}

.info>div {
    width: pxToRem(233px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.info>div>div{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(16px);
    color: #000000;
}

.acceptButton{
    position: absolute;
    right:pxToRem(48px);
    top:pxToRem(358px);
}