@import 'src/shared/styles/pxToRem';

* {
    box-sizing: border-box;
}

.chat {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: pxToRem(9px);
    width: pxToRem(620px);

    .chatListOverflow {
        overflow-y: auto;
        width: 100%;
        height: pxToRem(286px);
        direction: rtl;
        padding-left: pxToRem(16px);
        .chatList {
            direction: ltr;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: pxToRem(6px);
            width: 100%;
            min-height: 100%;
            font-variant-numeric: lining-nums proportional-nums;
            -webkit-text-stroke-width: 0.10000000149011612;
            -webkit-text-stroke-color: #000;
            font-family: 'Brutal Type';
            font-size: pxToRem(16px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.14px;
        }

        &::-webkit-scrollbar {
            width: 3px;
            background: rgba(17, 17, 17, 0.1);
            mix-blend-mode: normal;
            backdrop-filter: blur(2.5px);
            background-color: black;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background: rgba(255, 255, 255, 0.6);
        }

        &::-webkit-scrollbar-track {
            border-radius: 2px;
            height: 18px;
            background: rgba(255, 255, 255, 0.6);
        }
    }
    .chatInput {
        width: pxToRem(600px);
        height:auto;
        background: rgba(17, 17, 17, 0.65);
        backdrop-filter: blur(2.5px);
        border-radius: pxToRem(3px);
        display: flex;
        flex-flow:row nowrap;
        align-items: center;
        justify-content: space-between;
        margin-left: 12px;
        padding-left: pxToRem(3px);
        padding-right: pxToRem(8px);
        padding-top:pxToRem(8px);
        padding-bottom:pxToRem(8px);
        box-sizing:border-box;
        &Hide{
            display:none;
        }
        &Inactive {
            opacity: 0.5;
        }

        & form {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            height:100%;

            & button {
                background-color: rgb(0, 0, 0, 0);
                border: none;
                outline: none;
            }
            .buttonBlock {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: center;
                gap: pxToRem(10px);
                .tab {
                    width: fit-content;
                    padding: 0 pxToRem(8px) 0 0;
                    height: pxToRem(18px);
                    border-right: pxToRem(1px) solid #343333;
                    color: #515356;
                    font-family: 'Brutal Type';
                    font-size: pxToRem(12px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }
                .tag {
                    width: pxToRem(40px);
                    height: pxToRem(24px);
                    background: #fff;
                    border-radius: pxToRem(2px);
                    color: #141619;
                    text-align: center;
                    font-family: 'Brutal Type';
                    font-size: pxToRem(12px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &Me {
                        background: #be9ed5;
                    }
                    &Do {
                        background: #f5b365;
                    }
                    &B {
                        background: #d7d7d7;
                    }
                    &S {
                        background: #fbfbfa;
                    }
                    &W {
                        background: #fbfbfa;
                    }
                    .tagList {
                        position: absolute;
                        bottom: pxToRem(-3px);
                        left: 0;
                        width: 100%;
                        translate: 0 100%;
                        display: flex;
                        flex-flow: column nowrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: pxToRem(1px);
                        background-color: transparent;
                        .tagItem {
                            width: 100%;
                            height: pxToRem(24px);
                            border-radius: pxToRem(2px);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: #fff;
                            &Me {
                                background: #be9ed5;
                            }
                            &Do {
                                background: #f5b365;
                            }
                            &B {
                                background: #d7d7d7;
                            }
                            &S {
                                background: #fbfbfa;
                            }
                            &W {
                                background: #fbfbfa;
                            }
                        }
                    }
                }
            }
        }

        .chatInfo {
            display: flex;
            width: 100%;
            font-family: 'Brutal Type';
            font-style: normal;
            font-weight: 500;

            letter-spacing: -0.01em;
            text-transform: uppercase;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #ffffff;

            .chatStatus {
                padding: pxToRem(7px) pxToRem(4px);
                font-size: pxToRem(13px);

                background: rgba(6, 26, 97, 0.5);
                border-radius: pxToRem(2px);
            }

            .textarea {
                width: 99%;
                border: none;
                outline: none;
                background-color: rgb(0, 0, 0, 0);
                color: #fff;
                font-variant-numeric: lining-nums proportional-nums;
                font-family: 'Brutal Type';
                font-size: pxToRem(16px);
                font-style: normal;
                font-weight: 500;
                line-height: pxToRem(19px);
                letter-spacing: pxToRem(-0.14px);
                resize: none;
                overflow: hidden;
                height: pxToRem(20px);

                &::placeholder {
                    font-family: 'Brutal Type';
                    font-style: normal;
                    font-weight: 400;
                    font-size: pxToRem(14px);
                    letter-spacing: -0.01em;
                    font-feature-settings: 'pnum' on, 'lnum' on;
                    color: #515356;
                }
            }
        }

        & img {
            width: pxToRem(14px);
            cursor: pointer;
        }
    }
    .chatTagList {
        margin-left: 12px;
        display: flex;
        gap: 5px;
        user-select: none;

        .chatTagBox {
            padding: pxToRem(6px) pxToRem(10px);
            background: rgba(17, 17, 17, 0.5);
            border-radius: pxToRem(2px);

            cursor: pointer;

            font-family: 'Brutal Type';
            font-style: normal;
            font-weight: 500;
            font-size: pxToRem(13px);

            letter-spacing: -0.01em;
            font-feature-settings: 'pnum' on, 'lnum' on;

            color: #ffffff;
        }

        .chatTabBoxActive {
            background: #ecbb5b;
        }
    }
}
