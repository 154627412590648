@import '/src/shared/styles/pxToRem.scss';

.actionsBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    column-gap: pxToRem(30px);
    &:hover{
        cursor: pointer;
    }
}