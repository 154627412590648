@import '../../../shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(160px);
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: absolute;
    bottom: pxToRem(14px);
    left: pxToRem(503px);
    .itemContainer {
        width: pxToRem(80px);
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        gap: pxToRem(3px);
        .iconContainer {
            width: pxToRem(21px);
            height: pxToRem(26px);
            position:relative;
            .buttonName {
                position:absolute;
                right:pxToRem(-33px);
                top:pxToRem(-18px);
                width: pxToRem(26px);
                aspect-ratio: 1/1;
                border-radius: 50%;
                background: rgba(17, 17, 17, 0.4);
                backdrop-filter: blur(2.5px);
                display:flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-variant-numeric: lining-nums proportional-nums;
                font-family: 'Brutal Type';
                font-size: pxToRem(14px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: pxToRem(0.28px);
            }
            .icon {
                width: auto;
                height: 100%;
            }
        }
        .title {
            color: #a5a6a3;
            font-variant-numeric: lining-nums proportional-nums;
            font-family: 'Brutal Type';
            font-size: pxToRem(15px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: pxToRem(0.3px);
        }
    }
}
