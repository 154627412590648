@import '../../../../../../shared/styles/pxToRem.scss';

@mixin font(){
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    .toPay {
        @include font();
        margin: pxToRem(37px) 0 0 0;
        color: #686868;
        font-size: pxToRem(9px);
    }
    .summ {
        @include font();
        margin: pxToRem(3px) 0 0 0;
        color: #2fa92c;
        font-size: pxToRem(22px);
    }
    .nfc {
        margin: pxToRem(33px) 0 0 0;
        width: pxToRem(57px);
    }
    .directions {
        @include font();
        margin: pxToRem(45px) 0 0 0;
        color: #686868;
        text-align: center;
        font-size: pxToRem(7px);
    }
}
