@import '/src/shared/styles/pxToRem.scss';

.wrapper {
    position:absolute;
    top:pxToRem(64px);
    right:pxToRem(50px);
    color: #FFF;
    font-family: Roboto;
    font-size: pxToRem(36px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}