@import '/src/shared/styles/pxToRem.scss';

.cardShape {
    box-sizing: border-box;
    position: relative;
    width: pxToRem(662px);
    height: pxToRem(458px);
}

.mainInfo{
    position: absolute;
    top:pxToRem(144px);
    left:pxToRem(47px);
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    width:pxToRem(567px);
}