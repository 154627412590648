@import "src/shared/styles/pxToRem";

.container {
  width: pxToRem(644px);
  height: pxToRem(930px);
  background: #000000;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  box-sizing: border-box;
  padding: pxToRem(16px);
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(16px);
}

.column {
  display: flex;
  flex-direction: column;
  gap: pxToRem(8px);
  justify-content: space-between;
  flex: 1;
}

.block {
  background: #202020;
  flex-direction: row;
  display: flex;
  padding: pxToRem(12px) pxToRem(24px);
  gap: pxToRem(16px);
  align-items: center;
}

.title {
  font-size: pxToRem(24px);
  line-height: pxToRem(28px);
  margin-bottom: pxToRem(12px);
  color: #FFFFFF;
}

.row {
  display: flex;
  flex-direction: row;
  gap: pxToRem(16px);
  margin-bottom: pxToRem(6px);
}

.age {
  width: pxToRem(103px);
  height: pxToRem(50px);
  border-radius: pxToRem(10px);
  background: rgba(0, 0, 0, 0.50);
  color: #fff;
  font-family: Roboto;
  font-size: pxToRem(18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding-left: pxToRem(10px);
  margin-bottom:pxToRem(6px);
  &::placeholder{
    color: #949494;
  }
  &:active{
    outline: none;
  }
}

.name {
  flex: 1;
}

.ageBlock {
  flex: 0.4;
}

.birthPlace {
  width: 80%;
}

.fullFlex {
  flex: 1;
}

.nameBlock {
  flex: 0.8
}

.nationalityBlock{
  margin-bottom:pxToRem(6px);
}

.month{
  min-width:pxToRem(130px);
}
.day{
  min-width:pxToRem(90px);
}