@import "src/shared/styles/pxToRem";

.mainWrapper {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.mainLogo {
    height: pxToRem(117px);
    width: auto;
    transform: translate(pxToRem(0px), pxToRem(-17px));
}

.cartButton {
    width: pxToRem(73px);
    height: 100%;
    background: #7C787A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartButtonSelected {
    background: $cartColor;
}

.cartLogo {
    filter: invert(100%);
    width: pxToRem(37px);
    aspect-ratio: auto;
}

.cartLogoSelected {
    filter: $cartFilter;
    width: pxToRem(37px);
    aspect-ratio: auto;
}

.cartButton:hover {
    cursor: pointer;
}

.cartInfo {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: pxToRem(5px);
}

.cartInfo>div:first-child {
    box-sizing: border-box;
    padding-left: pxToRem(20px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: #FFFFFF;
}

.cartInfo>div:last-child {
    box-sizing: border-box;
    padding-left: pxToRem(20px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    color: #FFFFFF;
}

.trashButton {
    width: pxToRem(73px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.closePic {
    width: pxToRem(22px);
    height: pxToRem(22px);

}

.trashButton:hover {
    cursor: pointer;
}

.container {
    width: pxToRem(1172px);
    height: pxToRem(720px);
    display: grid;
    grid-template-areas: 'header header'
        'sidebar main';
    grid-template-columns: pxToRem(100px) 1fr;
    grid-template-rows: pxToRem(100px) 1fr;
    column-gap: pxToRem(10px);
    row-gap: pxToRem(5px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.goodsWindow {
    width: pxToRem(1062px);
    height: pxToRem(510px);
    box-sizing: border-box;
    padding: 0 0 0 pxToRem(20px);
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: pxToRem(15px);
    column-gap: pxToRem(18px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #ffffff transparent;
}

.header {
    grid-area: header;
    background: rgba(0, 0, 0, 0.6);
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.main {
    grid-area: main;
    background: rgba(0, 0, 0, 0.6);
}

.headerLeftContainer {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
}

.headerRightContainer {
    display: flex;
    width: pxToRem(297px);
    height: 100%;
    flex-flow: row nowrap;
}

.headerLeftContainer>div:first-child {
    position: relative;
}

.mainHeader {
    width: 100%;
    height: pxToRem(100px);
    margin-bottom: pxToRem(5px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.mainHeaderTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    color: $headerColor;
    margin-left: pxToRem(20px);
}

.title {
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    height: 100%;
    align-items: center;
    margin-left: pxToRem(15px);
}

.title>span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(48px);
    line-height: pxToRem(56px);
    color: #FFFFFF;
}

.topRightInfo {
    position: fixed;
    top: pxToRem(10px);
    right: pxToRem(10px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    color: #FFF;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .exitWrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        .exitIcon {
            width: pxToRem(40px);
            height: pxToRem(40px);
            margin-left: pxToRem(10px);
        }

        .topRightInfoText {
            font-size: pxToRem(20px);
            margin-left: pxToRem(78px);
        }
    }
    .userPaySourceWrapper{
        display: flex;
        flex-flow:row nowrap;
        justify-content: center;
        align-items: center;
        margin-left: pxToRem(30px);
        .paySourceIcons{
            width:pxToRem(30px);
            height:pxToRem(30px);
            margin-right:pxToRem(12px);
        }
        .paySourceValue{
            font-size: pxToRem(16px);
        }
    }

}