@import "src/shared/styles/pxToRem";

.path {
  cursor: pointer;
  fill: none;
  stroke: rgba(32, 32, 32, 0.95);

  &Active {
    stroke: #FFD80E;
  }
}

.wrapperOuter {
  position: relative;
}

.wrapperInner {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #FFF;
  font-family: Roboto;
  font-size: pxToRem(20px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: pxToRem(250px);
  text-align: center;
}

.circle {
  width: pxToRem(565px);
  height: pxToRem(565px);
}

.bigCircle {
  width: pxToRem(800px);
  height: pxToRem(800px);
}

.blocksWrapper {
  width: 81%;
  height: 81%;
  position: absolute;
  top: 9%;
  left: 9%;
  pointer-events: none;
}

.blocks {
  position: relative;
  height: 100%;
}
