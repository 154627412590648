@import '/src/shared/styles/pxToRem.scss';

.wrapper { 
	display: block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.text {
	padding-top: 0.05vh;
	padding-left: 0.5vh;
	margin: 0;
	color: rgb(255, 255, 255); /* rgb(50, 170, 35) <-- Green-ish */
	font-family: 'Consolas', Helvetica, monospace;
	text-shadow: pxToRem(2px) pxToRem(0px) pxToRem(2px) hsla(0, 0%, 0%, 0.3), pxToRem(-2px) 0 pxToRem(1px) hsla(0, 0%, 0%, 0.3), 0 pxToRem(2px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), 0 pxToRem(-2px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), pxToRem(1px) pxToRem(1px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), pxToRem(-1px) pxToRem(-1px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), pxToRem(1px) pxToRem(-1px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), pxToRem(-1px) pxToRem(1px) pxToRem(1px) hsla(0, 0%, 0%, 0.3), pxToRem(-1px) 0 black, 0 pxToRem(1px) black, pxToRem(1px) 0 black, 0 pxToRem(-1px) black;
	font-size: 2.35vh; /* font-size: 1.55rem; */
	font-weight: bold;
}

.noLeftPadding {
	padding-left: 0;
}

.floatRight {
	float: right;
}

.relative {
	position: relative;
}

.inline {
	display: inline-block;
}

.street {
	display: block;
	width: 100%;
}

.streetText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/*
This is were you set the colour for the svg images (bearing, crosshair etc.) 
Use this to "translate" the colour; https://codepen.io/sosuke/pen/Pjoqqp 
The "Green-ish" mentioned with the text would for example be like this: invert(51%) sepia(9%) saturate(4544%) hue-rotate(68deg) brightness(102%) contrast(85%) drop-shadow(-1px -1px 0px #00000096) drop-shadow(1px -1px 0px #00000096) drop-shadow(1px 1px 0px #00000096) drop-shadow(-1px 1px 0px #00000096);
Note: if you are going to change the colour, remember to also change it inside the bearing-directions.svg aswell (fill="white" -> fill="#32AA23" for "green-ish")
*/
.svg {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(51deg) brightness(106%) contrast(101%) drop-shadow(pxToRem(-1px) pxToRem(-1px) pxToRem(0px) #00000096) drop-shadow(pxToRem(1px) pxToRem(-1px) pxToRem(0px) #00000096) drop-shadow(pxToRem(1px) pxToRem(1px) pxToRem(0px) #00000096) drop-shadow(pxToRem(-1px) pxToRem(1px) pxToRem(0px) #00000096);
}

/* Helicopter Info (top left) */
.helicopterInfo {
	display: inline-block;
	margin-top: 2vh;
	margin-left: 1vw;
	margin-right: 0;
	width: 35vw;
	padding: 0;
}

.satelliteWrapper {
	width: 4vh;
}

.satelliteIcon {
    margin-bottom: -0.35vh;
    width: 100%;
}

.cameraLabel {
	margin-left: 0;
}

.infoData {
	display: inline-block;
	vertical-align: top;
}

.hiContainerLeft {
	display: inline-block;
}

.hiLatitude {
	display: block;
}

.hiSubLl {
	min-width: 8vw;
	width: calc(100% - 3.15vw);
}

.hiSubLr {
	float: right;
	margin: 0;
	margin-left: 0.15vw;
	width: 3vw;
}

.hiContainerRight {
	display: inline-block;
}

.hiLongitude {
	display: block;
}

.hiSubRl {
	min-width: 8vw;
	width: calc(100% - 2.25vw);
}

.hiSubRr {
	float: right;
	margin: 0;
	margin-left: 0.15vw;
	width: 2vw;
}

/* Target Info (top right) */
.targetInfo {
	float: right;
	margin-top: 2vh;
	margin-left: 0;
	margin-right: 1vw;
	max-width: 35vw;
	height: 25vh;
	padding: 0;
}

.taStreetWrapper {
	float: right;
	text-align: right;
}

.taContainerWrapper {
	float: right;
}

.taContainerLeft {
	float: left;
	margin-right: 1vw;
}

.taLatitude {
	display: block;
}

.taSubLl {
	min-width: 8vw;
	width: calc(100% - 3.15vw);
}

.taSubLr {
	float: right;
	margin: 0;
	margin-left: 0.15vw;
	width: 3vw;
}

.taContainerRight {
	float: right;
}

.taLongitude {
	display: block;
}

.taSubRl {
	min-width: 8vw;
	width: calc(100% - 2.5vw);
}

.taSubRr {
	float: right;
	margin: 0;
	margin-left: 0.25vw;
	width: 2.25vw;
}

.timedateContainer {
	margin-top: 4vh;
	margin-left: 1vw;
	width: 12vw;
	height: 6vh;
}

.centered {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.crossWrapper {
	position: relative;
}

.targetCross {
	height: 13vh;
}

.targetCrossInner {
	height: 3vh;
}

.lockBarContainer {
	display: block;
    position: absolute;
	left: 50%;
	top: 60%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	text-align: center;
}

.lockBarText {
	margin-bottom: 0.5vh;
}

.lockBarWrapper {
    background-color: rgba(0, 0, 0, 0.15);
    width: 15vw;
    height: 0.85vh;
    border-radius: 0.25vh;
	border: pxToRem(2px) solid hsla(0, 0%, 0%, 0.4);
}

.lockBarProgress {
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 2;
    height: 100%;
    width: 0%;
}

.relativeInfo {
	position: absolute;
	left: 50%;
	bottom: 2%;
	transform: translate(-50%, 0%);
}

.cameraPitch, .cameraHeading  {
	width: 2vw;
}

.cameraImg {
	position: relative;
	z-index: 44;
	width: 12.4vh;
}

.cameraLine {
	position: absolute;
	margin-left: -6.65vh;;
	margin-top: 1.4vh;
	height: 4.8vh;
	width: 0.8vh;
	transform: rotate(90deg);
	transform-origin: bottom;
}

.cameraInfo {
	position: absolute;
	bottom: 2%;
	left: 0;
	margin-left: 1vw;
}

.cameraInfoStack {
	margin-bottom: 2vh;
}

.lockState {
	margin-right: 0.8vh;
}

.lockStateActive {
	color: rgb(200, 0, 0);
}

.zoomArrow {
	position: absolute;
	width: 2vh;
	z-index: 2;
    transform-origin: center;
	margin-top: 0.3vh;
	margin-left: 0vh;
}

.zoomBar {
	height: 2.25vh;
}


.northInfobox {
	margin-top: 0vh;
	margin-left: 1vh;
	width: 15vw;
	height: 15vh;
	background-color: rgba(78, 36, 36, 0.288);
}

// .compassArrow{
// 	transform-origin : center left;
// 	background:#000;
// 	width: 5vw;
// 	height: 2vh;
// 	position: absolute;
// }




