@import "src/shared/styles/pxToRem";

$width-container: 410px;
$height-container: 84px;
$sub-font: 16px;
$sub-line-height: 23px;
$sub-border: 10px;
$sub-box-padding-top: 15px;
$sub-box-padding-left: 15px;
$scale: 1;

.container {
    background: rgba(32, 32, 32, 0.9);
    font-family: 'Roboto';
    font-style: normal;
    width: pxToRem($width-container, $scale);
    height: pxToRem($height-container, $scale);
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem($sub-font, $scale);
    line-height: pxToRem($sub-line-height, $scale);
    color: #FFFFFF;
    border: pxToRem($sub-border, $scale)solid rgba(0, 0, 0, 0.8);
    display:flex;
    flex-flow:column nowrap;
    justify-content: center;
    align-items: flex-start;
    gap:pxToRem(6px);
    padding:0 0 0 pxToRem(15px);
}