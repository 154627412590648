@import "src/shared/styles/pxToRem";

.container {
    border-radius: pxToRem(10px);
    background: rgb(255, 216, 14);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: pxToRem(16px);
    border: none;

    color: rgb(0, 0, 0);
    font-size: pxToRem(18px);
    font-weight: 500;
    line-height: pxToRem(21px);
    cursor: pointer;
}

.full {
    width: 100%;
}

.secondary {
    background: rgb(29, 29, 29);
    color: rgb(148, 148, 148);
}