@import "/src/shared/styles/pxToRem.scss";

.wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
}

.wrapperHide{
    display:none;
}

.block {
    width: fit-content;
    height: 25px;
    box-sizing: border-box;
    padding: 0 7px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    border-radius: 2px;
    background: rgba(17, 17, 17, 0.50);
}

.title {
    color: #FFF;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Brutal Type;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.14px;
}

.value {
    width: 17px;
    height: 19px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFD80E;
    color: #000;
    font-family: Brutal Type;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}