@import '/src/shared/styles/pxToRem.scss';

.wrapper {
    position: absolute;
    top: pxToRem(50px);
    left: pxToRem(94px);
    width: pxToRem(614px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    .title {
        color: #FFF;
        font-family: Roboto;
        font-size: pxToRem(36px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .flexContainer {
        width: pxToRem(500px);
        height:pxToRem(495px);
        box-sizing: border-box;
        padding:pxToRem(10px);
        display: flex;
        flex-flow:row wrap;
        overflow:auto;
        gap: pxToRem(15px);
        margin-top:pxToRem(31px);
        scrollbar-width: none;
    }
    // .flexContainer {
    //     width: pxToRem(480px);
    //     height:pxToRem(495px);
    //     display: flex;
    //     flex-flow:row wrap;
    //     overflow:auto;
    //     gap: pxToRem(15px);
    //     margin-top:pxToRem(31px);
    //     scrollbar-width: none;
    // }

}