@import "src/shared/styles/pxToRem";

$scale: 1;

.container {
  height: pxToRem(50px, $scale);
  background: rgba(0, 0, 0, 0.5);
  padding-left: pxToRem(10px, $scale);
  display: flex;
  align-items: center;

  color: #949494;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(18px, $scale);
  line-height: pxToRem(19px, $scale);
}

.input {
  color: #fff;
  background: transparent;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: pxToRem(18px, $scale);

  &:focus {
    outline: none;
  }
}


.rounded {
    border-radius: pxToRem(10px, $scale);
}
