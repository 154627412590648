@import "src/shared/styles/pxToRem";

.container {
    width: 100%;
}



.button {
    width: pxToRem(270px);
    margin: auto;
    margin-top: pxToRem(40px);
}

.forgotPassword {
    color: rgb(148, 148, 148);
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: pxToRem(19px);
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: pxToRem(10px);

    & button {
        color: rgb(255, 216, 14);
        font-size: pxToRem(16px);
        font-weight: 400;
        line-height: pxToRem(19px);
        background: none;
        border: none;
        margin-left: pxToRem(6px);
        width: auto;
    }
}
