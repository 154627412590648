@import "src/shared/styles/pxToRem";

.title {
    color: rgb(255, 255, 255);
    font-size: pxToRem(36px);
    line-height: pxToRem(42px);
    margin-bottom: pxToRem(16px);
}

.container {
    width: pxToRem(447px);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
}

.description {
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: rgb(206, 206, 206);
    text-align: center;
    margin-bottom: pxToRem(18px);
    width: 80%;
}
