.container {
    width: 966px;
    height: 681px;
    background: url(./assets/fon.png);
    display: grid;
    grid-template-areas: 'header header'
        'sidebar main';
    grid-template-columns: 101px 1fr;
    grid-template-rows: 116px 1fr;
    column-gap: 10px;
    position: relative;
}

.header {
    grid-area: header;
    background: #18181A;
    box-shadow: 4px -2px 15px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0 0 17px;
}

.logo {
    width: 324px;
    height: 48px;
    background: url(./assets/LOGO.svg) no-repeat;
    background-size: contain;
}

.mainHeader {
    width: 100%;
    height: 88px;
    margin-bottom: 5px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
}

.subCategories {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.subCategorie {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #9A9A9A;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    padding: 5px 18px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.subCategorie:hover{
    cursor: pointer;
}

.subCategorieSelected{
    border-bottom: 3px solid #BE8F54;
    ;
}





.main {
    grid-area: main;
    height:565px;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
    box-sizing: border-box;
    overflow: hidden;
}

.goodsWindow {
    width: 100%;
    height: 470px;
    box-sizing: border-box;
    padding: 0 0 0 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: 15px;
    column-gap: 18px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #ffffff transparent;
}
