@import '/src/shared/styles/pxToRem.scss';

.wrapper {
    width: pxToRem(150px);
    height: pxToRem(150px);
    background: #262626;
    display:flex;
    flex-flow:column nowrap;
    box-sizing: border-box;
    border:pxToRem(1px) solid transparent;
    position:relative;
    transition: 0.2s;

    .imgContainer{
        flex-grow:1;
        display:flex;
        justify-content: center;
        align-items: center;
        .icon{
            height:pxToRem(94px);
        }
    }

    .title {
        width: 100%;
        height: pxToRem(43px);
        background: #313131;
        color: #FFF;
        font-family: Roboto;
        font-size: pxToRem(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}
.hover{
    transition: 0.2s;
    &:hover{
        border: pxToRem(1px) solid #FFD80E;
        transition:0.2s; 
    }
}