@import '/src/shared/styles/pxToRem.scss';

.plateReaderBox {
    width: pxToRem(225px);
    height: pxToRem(330px);

    position: absolute;

    top: calc(50% - (pxToRem(225px) / 2));
    left: calc(50% - (pxToRem(330px) / 2));

    background: linear-gradient(to bottom, rgb(70, 70, 70), rgb(45, 45, 45));
    border: pxToRem(3px) solid rgb(0, 0, 0);

    z-index: 14;
}

.plateReaderBox .title {
    text-align: center;
    font-size: pxToRem(20px);
    padding: pxToRem(5px) 0;
    margin: 0 auto;
    color: rgb(255, 255, 255);
    background-color: rgb(20, 22, 18);
}

.plateReaderBox .header {
    width: 100%;
}

.plateReaderBox .container {
    width: 100%;
    height: pxToRem(200px);

    display: grid;
    grid-template-rows: pxToRem(60px) pxToRem(70px) pxToRem(60px);
    align-items: center;
    justify-items: center;
}

.plateReaderBox .container .btn {
    width: pxToRem(140px);
    height: pxToRem(35px);
    border-radius: pxToRem(10px);
    font-size: pxToRem(16px);
    border: none;
}

.plateReaderBox .container .btn:hover {
    background-color: rgb(255, 255, 255);
}

.plateReaderBox .container .btn:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}

.plateReaderBox .container .plate_input {
    width: 75%;
    height: pxToRem(50px);
    font-family: "Plate-Font";
    text-align: center;
    font-size: pxToRem(38px);
    text-transform: uppercase;
    padding: 0;
    padding-bottom: pxToRem(15px);
    margin-bottom: pxToRem(-15px);
}

.plateReaderBox .close {
    width: pxToRem(80px);
    height: pxToRem(20px);

    position: absolute;
    left: 0;
    right: 0;
    bottom: pxToRem(10px);
    margin: auto;

    border-radius: pxToRem(10px);
    border: none;
    background-color: rgb(225, 225, 225);
}

.plateReaderBox .close:hover {
    background-color: rgb(255, 255, 255);
}

.plateReaderBox .close:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}