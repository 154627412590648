.criticalStateScreen {
    width: 1920px;
    height: 1080px;
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.radius1 {
    width: 100%;
    height: 200%;
    background: radial-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.9) 70%),
        radial-gradient(circle, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.9) 80%);
}

.info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.reanimationLogo {
    width: 100px;
    height: 100px;
}

.container {
    display: flex;
    flex-flow: column nowrap;
    font-style:normal;
    color:#CCC8C6;
    line-height: normal;
    .bigtext{
        font-family: Brutal Type;
        font-size: 32px;
        font-weight: 700;
        text-transform: capitalize;
        margin-top: 18px;
    }
    .smalltext{
        width: 358px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        margin-top: 10px;
    }
}

.counter {
    color: #CCC8C6;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top:51px;
}

.buttonBlock{
    display:flex;
    flex-flow:row nowrap;
    margin-top:18px;
    gap:28px;
}

.btn {
    width:208px;
    height:47px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #ffd80e;
    color: #121212;
    border-radius:5px;
    &:hover{
        opacity: 0.8;
    }
    &:active{
        opacity: 0.5;
    }
}