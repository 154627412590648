@import "src/shared/styles/pxToRem";

.container {
    display: flex;
    gap: pxToRem(20px);
}

.icon {
    height: pxToRem(30px);
    width:  pxToRem(30px);
}

.iconHide {
    display:none;
}