@import "src/shared/styles/pxToRem";

.container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: linear-gradient( rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65) );
}

.content {
    max-width: pxToRem(1200px);
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.logo {
    position: absolute;
    width: pxToRem(73px);
    fill: #FFD80E;
    height: auto;
    right: pxToRem(40px);
    top: pxToRem(40px);
}

.col {
    width: 50%;
    display: flex;
    justify-content: center;
}