@import "src/shared/styles/pxToRem";

.container {
    font-family: 'Brutal Type';
    //width: pxToRem(164px);
    //height: pxToRem(45px);
    display: flex;
    flex-flow: column nowrap;
    //flex: 1;
    justify-content: center;
    align-items: center;
    gap:pxToRem(0px);
}

.info {
    display: flex;
    flex-flow: row nowrap;
    gap:pxToRem(20px);
}

.secondTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: pxToRem(0px);
    align-items: center;
}

.idText {
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(20px);
    line-height: pxToRem(19px);
    letter-spacing: 0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: rgba(255, 255, 255, 1);
}

.text {
    font-size: pxToRem(15px);
    font-weight: 700;
    line-height: pxToRem(19px);
    letter-spacing: 0.02em;
    text-align: left;
    color: #ffffff;
}

.pictureImg {
    width: pxToRem(81px);
    height: pxToRem(42px);
}
