@import '/src/shared/styles/pxToRem.scss';

.message{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:fit-content;
    height:fit-content;
    color:#fff;
    font-size:pxToRem(20px);
}