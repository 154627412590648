@import 'src/shared/styles/pxToRem';

.messageNormalName {
    color: rgb(221, 221, 221);
}
.messageNormalText {
    color: rgb(181, 181, 181);
}

.messageMe {
    color: #be9ed5;
}

.messageDo {
    color: #f5b365;
}

.messageTodoStart {
    color: #fff;
}
.messageTryGood {
    color: green;
}
.messageTryBad {
    color: red;
}

.messageS {
    color: rgb(255, 255, 255);
}

.messageW {
    color: rgb(31, 31, 31);
}



//-------------------------------------------------------------
.message {
    font-variant-numeric: lining-nums proportional-nums;
    -webkit-text-stroke-width: 0.10000000149011612;
    -webkit-text-stroke-color: #000;
    font-family: 'Brutal Type';
    font-size: pxToRem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: pxToRem(-0.14px);
    -webkit-text-stroke-width: pxToRem(0.5px);
    -webkit-text-stroke-color: #000;
    overflow-wrap: break-word;

}

.rpText{
    color:#fff;
    &::first-letter{
        text-transform: uppercase;
    }
}
.rpName{
    color:#D7D7D7;
}
.meText{
    color:#BE9ED5;
    &::first-letter{
        text-transform: lowercase;
    }
}
.meName{
    color:#BE9ED5;
}
.do{
    color:#f5b365;
}
.toDoText{
    color:#FBFBFA;
}
.toDoName{
    color:#BE9ED5
}
.b{
    color:#D7D7D7;
}
.s{
    color:#FBFBFA;
}
.w{
    color:#FBFBFA;
}
.error{
    color:#f5b365;
    white-space: pre-wrap;
}
