@import '/src/shared/styles/pxToRem.scss';

.uiSettingsBox {
    width: pxToRem(250px);
    height: pxToRem(400px);

    position: absolute;
    top: calc(50% - (pxToRem(250px) / 2));
    left: calc(50% - (pxToRem(400px) / 2));

    background: linear-gradient(to bottom, rgb(70, 70, 70), rgb(45, 45, 45));
    border: pxToRem(3px) solid rgb(0, 0, 0);

    z-index: 13;
}

.uiSettingsBox .title {
    text-align: center;
    font-size: pxToRem(20px);
    padding: pxToRem(5px) 0;
    margin: 0 auto;
    color: rgb(255, 255, 255);
    background-color: rgb(20, 22, 18);
}

.uiSettingsBox .header {
    width: 100%;
}

.uiSettingsBox .scaling_container {
    height: pxToRem(225px);
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.uiSettingsBox .scaling {
    height: pxToRem(70px);

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    place-items: center center;
}

.uiSettingsBox .scaling p {
    font-size: pxToRem(18px);
    margin: 0 auto;
    text-align: center;
    color: rgb(255, 255, 255);
}

.uiSettingsBox .scaling .multiplier {
    font-size: pxToRem(16px);
}

.uiSettingsBox .scaling .symbol {
    width: pxToRem(40px);
    height: pxToRem(40px);

    background-color: rgb(225, 225, 225);
}

.uiSettingsBox .scaling .symbol:hover {
    background-color: rgb(255, 255, 255);
}

.uiSettingsBox .scaling .symbol:active {
    background-color: rgb(190, 190, 190);
}

.uiSettingsBox .scaling .minus {
    clip-path: polygon(0 35%, 100% 35%, 100% 65%, 0 65%);
}

.uiSettingsBox .scaling .plus {
    clip-path: polygon(0 35%, 35% 35%, 35% 0, 65% 0, 65% 35%, 100% 35%, 100% 65%, 65% 65%, 65% 100%, 35% 100%, 35% 65%, 0 65%);
}

.uiSettingsBox .safezone_container {
    width: 85%;
    margin: 0 auto;
}

.uiSettingsBox .safezone_container p,
.uiSettingsBox .safezone_container span {
    font-size: pxToRem(18px);
    margin: 0 auto;
    text-align: center;
    color: rgb(255, 255, 255);
}

.uiSettingsBox .safezone_container .slider {
    width: 100%;
    height: pxToRem(10px);
    margin: pxToRem(10px) 0;

    border-radius: pxToRem(5px);

    background-color: rgb(180, 180, 180);
}

.uiSettingsBox .safezone_container .slider:focus {
    outline: none;
}

.uiSettingsBox .safezone_container .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: pxToRem(15px);
    height: pxToRem(25px);
    background: rgb(84, 210, 255);
    cursor: pointer;
}

.uiSettingsBox .safezone_container .slider::-moz-range-thumb {
    width: pxToRem(15px);
    height: pxToRem(25px);
    background: rgb(84, 210, 255);
    cursor: pointer;
}

.uiSettingsBox .close {
    width: pxToRem(80px);
    height: pxToRem(20px);

    position: absolute;
    left: 0;
    right: 0;
    bottom: pxToRem(30px);
    margin: auto;

    border-radius: pxToRem(10px);
    border: none;
    background-color: rgb(225, 225, 225);
}

.uiSettingsBox .close:hover {
    background-color: rgb(255, 255, 255);
}

.uiSettingsBox .close:active {
    background-color: rgb(190, 190, 190);
    padding: 0;
}