@import "src/shared/styles/pxToRem";

.mainShape {
    grid-area: main;
    width: pxToRem(1062px);
    height: pxToRem(615px);
    background: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    padding: 0 pxToRem(20px) 0 pxToRem(20px);
    display: flex;
    flex-flow: column nowrap;
}

//----------------header------------------------
.header {
    width: 100%;
    height: pxToRem(64px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(24px);
    line-height: pxToRem(28px);
    color: $headerColor;
    margin: 0 0 pxToRem(22px) 0;
    box-sizing: border-box;
    flex-shrink:0;
}

//--------------------main-----------------------
.goods {
    flex-grow: 1;
    overflow: auto;
    scrollbar-width:none;
}

.goods::scrollbar{
    width:0;
}

.product {
    width: 100%;
    height: pxToRem(76px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    background: rgba(75, 75, 75, 0.35);
    backdrop-filter: blur(pxToRem(2px));
    margin: 0 0 pxToRem(16px) 0;
}

.productIcon {
    height: pxToRem(66px);
    aspect-ratio: auto;
    margin: 0 0 0 pxToRem(20px);
}

.description {
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: pxToRem(2px) pxToRem(20px);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(14px);
    line-height: pxToRem(16px);
    color: #FFFFFF;
}

.valueButton {
    width: pxToRem(25px);
    height: pxToRem(25px);
    background:$backColor;
    backdrop-filter: blur(pxToRem(25px));
    border-radius: pxToRem(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Brutal Type';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14px);
    line-height: pxToRem(17px);
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $fontColor;
}

.valueButton:hover {
    cursor: pointer;
}

.value {
    width: pxToRem(31px);
    height: pxToRem(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Brutal Type';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(14px);
    line-height: pxToRem(17px);
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
}

.productPrice {
    width: pxToRem(326px);
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(16px);
    line-height: pxToRem(19px);
    color: #FFFFFF;
}

.deleteProduct {
    width: pxToRem(90px);
    height: 100%;
    //background: url(../../assets/deleteProductPic.svg) center no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteProductIcon{
    width:pxToRem(25px);
    height:pxToRem(25px);
}

.deleteProduct:hover {
    cursor: pointer;
}



//---------------------footer--------------------
.footer {
    width: 100%;
    height: pxToRem(67px);
    border-top: 1px solid #505050;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    column-gap: pxToRem(30px);
    flex-shrink:0;
}

.saleInfo>span:first-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: #FFFFFF;
    margin-right: pxToRem(10px);
}

.saleInfo>span:last-child {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: pxToRem(20px);
    line-height: pxToRem(23px);
    color: #FFFFFF;
}

.buyButton {
    width: pxToRem(172px);
    height: pxToRem(39px);
    background: $backColor;
    border-radius: pxToRem(10px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: pxToRem(18px);
    line-height: pxToRem(21px);
    color: $fontColor;
}

.buyButton:hover {
    cursor: pointer;
}