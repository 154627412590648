@import "src/shared/styles/pxToRem";

$color: rgba(32, 32, 32, 0.95);

.container {
  position: relative;
  height: 100%;
}

.path {
  cursor: pointer;
  fill: none;
  stroke: $color;
  pointer-events: visible;

  &Active {
    stroke: #FFD80E;
  }
}

.paths {
  width: 100%;
  height: 100%;
}


.blocksWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.circle {
  fill: $color;
}

.wrapper {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
