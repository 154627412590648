.suppression {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.radius {
	opacity: 1;
	width: 100%;
	height: 200%;
}

.radius1 {
	background: radial-gradient(rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%),
		radial-gradient(circle, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%);
	opacity: 0;
	transition: 7s;
}

.radius2 {
	background: radial-gradient(rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%),
		radial-gradient(circle, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 60%);
	opacity: 0;
	transition: 5s;
}

.radius3 {
	background: radial-gradient(rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%),
		radial-gradient(circle, rgba(255, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%);
	opacity: 0;
	transition: 3.8s;
}

.radius4 {
	background:
		url(./assets/blood3.png),
		radial-gradient(rgba(255, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 50%),
		radial-gradient(circle, rgba(255, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 50%);
	background-size: contain;
	mix-blend-mode: multiply;
	opacity: 0;
	transition: 3.8s;
}